// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ProductPerformanceTabsState {
  // productPerformanceTab is a value of number type (1-4) and a label of sting type ('summary', 'performance', 'reconsider')
  id: 0 | 1 | 2;
  value: number;
  label: 'summary' | 'performance' | 'reconsider';
  openModal: boolean;
  openModalType: 'create' | 'edit' | null;
}

const initialState: ProductPerformanceTabsState = {
  id: 0,
  value: 1,
  label: 'summary',
  openModal: false,
  openModalType: null
};

const productPerformanceTabSlice = createSlice({
  name: 'productPerformanceTab',
  initialState,
  reducers: {
    changeProductPerformanceTabs: (state, action) => {
      if (action.payload === 1) {
        state.id = 0;
        state.value = 1;
        state.label = 'summary';
      } else if (action.payload === 2) {
        state.id = 1;
        state.value = 2;
        state.label = 'performance';
      } else if (action.payload === 3) {
        state.id = 2;
        state.value = 3;
        state.label = 'reconsider';
      }
      state.value = action.payload;
    },
    toggleCreateProductModal: (state, action) => {
      state.openModal = action.payload;
      state.openModalType = 'create';
    },
    toggleEditProductModal: (state, action) => {
      state.openModal = action.payload;
      state.openModalType = 'edit';
    }
  }
});

// export the productPerformanceTab reducer as an action creator
// Redux toolkit automatically generates action creators for
// each reducer function passed into the reducers object
export const {
  changeProductPerformanceTabs,
  toggleCreateProductModal,
  toggleEditProductModal
} = productPerformanceTabSlice.actions;

// exporting the productPerformanceTabSlice.reducer function processes the actions
// passed into the redux store, and returns an updated version of the state
// each slice gets its own reducer function that is responsible for updating
// the state for that part of the data
export default productPerformanceTabSlice.reducer;
