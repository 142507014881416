import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// RTK Query Import
import { api } from './services/api';

// AppThunk Imports
import type { AnyAction } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';

// import each individual reducer from the slices folder
import authReducer from './slices/auth-slice';

import { reducer as communicationCenterReducer } from './slices/communication-center-slice';
import calendarReducer from './slices/calendar-slice';
import notificationsReducer from './slices/calendar-notification-slice';
import communicationReducer from './slices/communication-slice';
import hideTimeframeSelectorReducer from './slices/hide-timeframe-selector-slice';
import marketingSourcesReducer from './slices/marketing-source-slice';
import userPermissionsReducer from '@/lib/state/slices/user-permissions-slice';
import callCenterReducer from '@/lib/state/slices/call-center-slice';
// Filters
import filterCustomerFeedbackReducer from './slices/filter-customer-feedback-slice';
import filterCommunicationTheaterContactListReducer from './slices/filter-communication-theater-contact-list-slice';
import filterTimelineItemsReducer from './slices/filter-timeline-items-slice';
import timeframeReducer from './slices/timeframe-slice';
import campaignsSelectorReducer from './slices/campaigns-selector-slice';
import shoppingTrendsSplineReducer from './slices/shopping-trends-spline-selector-slice';

// Selectors
import selectProductPerformanceAndReconsiderIDsReducer from './slices/select-product-performance-and-reconsider-ids-slice';
import selectMarketingAppraisalSourceIDReducer from './slices/select-marketing-appraisal-source-slice';
import selectMultiLocationStoreIdSlice from '@/lib/state/slices/select-multi-location-store-id-slice';
import contactProfileSidebarReducer from './slices/contact-profile-sidebar-slice';
import selectMultiOperationAnalysisIdsAndObjectsReducer from './slices/select-multi-operation-analysis-ids-and-objects-slice';
import multiOperationsLocationTableRadioSelectReducer from '@/lib/state/slices/multi-operations-location-table-radio-select-slice';
import selectProspectsIdReducer from './slices/select-prospects-id-slice';
import selectCommunicationVolumeSplineDataReducer from './slices/select-communication-volume-spline-data-slice';
// Tabs
import allContactsTabReducer from './slices/page-tabs-views/all-contacts-tabs-slice';
import customerBehaviorsTabsReducer from './slices/page-tabs-views/customer-behaviors-tabs-slice';
import customerFeedbackTabsReducer from './slices/page-tabs-views/customer-feedback-tabs-slice';
import employeeEngagementTabsReducer from './slices/page-tabs-views/employee-engagement-tabs-slice';
import marketingAppraisalTabsReducer from './slices/page-tabs-views/marketing-appraisal-tabs-slice';
import marketingCampaignsTabsReducer from './slices/page-tabs-views/marketing-campaigns-tabs-slice';
import landingPagesTabsReducer from './slices/page-tabs-views/landing-pages-tabs-slice';
import specialsNarrativeTabsReducer from './slices/page-tabs-views/specials-narrative-tabs-slice';
import marketingGroupsTabsReducer from './slices/page-tabs-views/marketing-groups-tabs-slice';
import multiOperationTabsReducer from './slices/page-tabs-views/multi-operation-tabs-slice';
import productPerformanceTabReducer from './slices/page-tabs-views/product-performance-tabs-slice';
import prospectManagementTabsReducer from './slices/page-tabs-views/prospect-management-tabs-slice';
import shoppingTrendsTabsReducer from './slices/page-tabs-views/shopping-trends-tabs-slice';
import communicationVolumeTabSlice from './slices/page-tabs-views/communication-volume-tabs-slice';
import marketingGroupsCreationSlice from './slices/marketing-groups-creation-slice';
import displayMessagingDashboardContainerReducer from './slices/display-messaging-dashboard-container-slice';
import aiRecommendationsTabsReducer from '@/lib/state/slices/page-tabs-views/ai-recommendations-tabs-slice';
import productFormReducer from './slices/product-form-slice';

import settingsTabReducer from './slices/page-tabs-views/settings-tabs-slice';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';

//charts
import salesSplineReducer from './slices/sales-spline-slice';

//Hooks
import { listenerMiddleware, startAppListening } from './hooks';

//prospect reducer

// test COUNTER - pusher
import counterReducer from './slices/notification-bell-slice';
// import pusherMiddleware from '@/lib/state/slices/pusher-middleware';
import logger from './slices/logger';
import prospectManagementTabsSlice from '@/lib/state/slices/page-tabs-views/prospect-management-tabs-slice';

// const pusherConfig = {
//   key: 'ba4d144ab20fb212f010',
//   cluster: 'us3',
//   // auth endpoint for private channels
//   // e.g. for Laravel https://example.com/api/broadcasting/auth
//   authEndpoint: `https://api.ripemetrics.com/broadcasting/auth`
// };

// const getChannels = (pusherConfig: any) => {
//   try {
//     const client = new Pusher(pusherConfig.key, {
//       cluster: pusherConfig.cluster,
//       forceTLS: true,
//       authEndpoint: pusherConfig.authEndpoint,
//       auth: {
//         headers: {
//           Accept: 'application/json',
//           'Access-Control-Allow-Origin': 'https://rimpemetrics.com',
//           'Access-Control-Request-Headers': 'x-xsrf-token'
//         }
//       }
//     });
//     const channels = new Echo({
//       broadcaster: 'pusher',
//       client
//     });
//     return channels;
//   } catch (error) {
//     console.error('Error creating Pusher client:', error);
//   }
// };

// const channels = getChannels(pusherConfig);
// console.log('channels', channels);

// // listen to a private channel "ripemetrics"
// channels
//   ?.private(`ripemetrics`)
//   .listen(`ripemetrics`, (event: any, data: any) => {
//     console.log('event and data', event, data);
//   });

// Add one or more listener entries that look for specific actions. They may contain any sync or async logic, similar to thunks.
// This listener will fire whenever the `campaignSelector` action is dispatched
startAppListening({
  predicate: (action, currentState, prevState) =>
    currentState.campaignsSelector.value !== prevState.campaignsSelector.value,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      api.util.invalidateTags(['EnhancedCampaignsInitiatedCampaignsData'])
    );
  }
});

// this is where you would change what storage the persisted state is saved to currently it is local storage
const persistConfig = {
  key: 'root',
  storage,
  // if needed will completely overwrite the previous state
  // stateReconciler: hardSet

  // The blacklist and whitelist properties take an array of strings. Each string must match a part of the state that is managed by the reducer we pass to persistReduce
  // blacklist property, we can specify which part of state not to persist, while the whitelist property does the opposite

  blacklist: ['auth', 'callCenter']
  // whitelist: ['users']
};
// combine reducers method combines all reducers into a single variable which we pass to the persistReducer method below
const rootReducer = combineReducers({
  // combine all the individual reducers into a single object so it can act as one data store

  // RTK Query API reducer from src\lib\state\services\api.ts
  [api.reducerPath]: api.reducer,

  // Auth
  auth: authReducer,
  userPermissions: userPermissionsReducer,

  // Pages
  calendar: calendarReducer, // calendar page
  notifications: notificationsReducer, // notifications page
  communication: communicationReducer, // communication page
  communicationCenter: communicationCenterReducer, // old communication center page, remove after BI overview page is complete
  marketingSources: marketingSourcesReducer,
  callCenter: callCenterReducer,
  //Filters
  filterCustomerFeedback: filterCustomerFeedbackReducer,
  filterTimelineItems: filterTimelineItemsReducer,
  filterCommunicationTheaterContactList:
    filterCommunicationTheaterContactListReducer,
  displayMessagingDashboardContainer: displayMessagingDashboardContainerReducer,

  // Selectors

  campaignsSelector: campaignsSelectorReducer,
  contactProfileSidebar: contactProfileSidebarReducer,
  shoppingTrendsSplineSelector: shoppingTrendsSplineReducer,
  selectProductPerformanceAndReconsiderIDs:
    selectProductPerformanceAndReconsiderIDsReducer,
  selectMarketingAppraisalSourceID: selectMarketingAppraisalSourceIDReducer,
  timeframe: timeframeReducer,
  hideTimeframeSelector: hideTimeframeSelectorReducer,
  selectMultiLocationStoreId: selectMultiLocationStoreIdSlice,
  selectMultiOperationAnalysisIdsAndObjects:
    selectMultiOperationAnalysisIdsAndObjectsReducer,
  multiOperationsLocationTableRadioSelect:
    multiOperationsLocationTableRadioSelectReducer,
  prospectsIdSelect: selectProspectsIdReducer,
  selectCommunicationVolumeSplineData:
    selectCommunicationVolumeSplineDataReducer,

  // Tabs
  allContactsTab: allContactsTabReducer,
  customerBehaviorsTab: customerBehaviorsTabsReducer,
  customerFeedbackTab: customerFeedbackTabsReducer,
  employeeEngagementTab: employeeEngagementTabsReducer,
  marketingCampaignsTab: marketingCampaignsTabsReducer,
  marketingGroupsTab: marketingGroupsTabsReducer,
  marketingGroupsCreation: marketingGroupsCreationSlice,
  marketingAppraisalTab: marketingAppraisalTabsReducer,
  multiOperationTab: multiOperationTabsReducer,
  productPerformanceTab: productPerformanceTabReducer,
  prospectManagementTab: prospectManagementTabsReducer,
  shoppingTrendsTab: shoppingTrendsTabsReducer,
  landingPagesTab: landingPagesTabsReducer,
  specialsNarrativeTab: specialsNarrativeTabsReducer,
  settingsTab: settingsTabReducer,
  aiRecommendationsTab: aiRecommendationsTabsReducer,
  communicationVolumeTab: communicationVolumeTabSlice,

  //charts
  setSalesSpline: salesSplineReducer,

  // test counter slice
  counter: counterReducer,

  //reducer for prospect form
  prospectForm: prospectFormReducer,

  // product form reducer
  productForm: productFormReducer
});

//prospect reducer
import prospectFormReducer from './slices/prospect-form-slice';

// this is persistedReducer is what we later use as the reducers in the configureStore below
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
  configureStore({
    // redux devtools extension is only available in development mode
    devTools: process.env.NODE_ENV === 'development',
    // combine all the individual reducers into a single object
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .prepend(listenerMiddleware.middleware)
        .concat(api.middleware)
        .concat([thunk, logger]),

    ...options
  });

export const store = createStore();
export const persistor = persistStore(store);
// Export the type of the store dispatch actions into the redux store
export type AppDispatch = typeof store.dispatch;

// Export the type of the store state. ReturnType is a TypeScript utility type that transforms
// the type definition of a function into the type of its return value so RootState contains
// the type definition that matches all the data in the redux store
export type RootState = ReturnType<typeof store.getState>;

// Export the AppThunk type for use in slices
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;
