import '@/lib/styles/global.css';
import { useEffect, FC } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { RootState, store } from '@/lib/state/store';
import nProgress from 'nprogress';
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SplashScreen } from '@/components/_shared/layouts/splash-screen';
import {
  DarkModeConsumer,
  DarkModeProvider
} from '@/lib/contexts/dark-mode-context';
import { AuthConsumer, AuthProvider } from '@/lib/contexts/auth-context';
import { gtmConfig } from '@/lib/gtm/config';
import { gtm } from '@/lib/gtm/gtm';
import { flare } from '@flareapp/flare-client';

import { createTheme } from '@/lib/theme';
import { createEmotionCache } from '@/lib/utils/create-emotion-cache';
import { FlareErrorBoundary } from '@flareapp/flare-react';

import '@/lib/i18n/i18n';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import axios from 'axios';
import ErrorBoundary from '@/components/_shared/error-boundary/Error';

import FacebookPixel from '@/lib/utils/facebook-pixel';

import LinkedInTrackingCode from '@/lib/utils/linkedin-tracking-code';
import GoogleGlobalSiteTag from '@/lib/utils/google-global-site-tag';
import StripeBillingScript from '@/lib/utils/stripe-billing-script';
import { HubspotProvider } from 'next-hubspot';

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();
flare.light(process.env.NEXT_PUBLIC_FLARE);

const App: FC<React.PropsWithChildren<EnhancedAppProps>> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['Access-Control-Allow-Origin'] =
    'https://ripemetrics.com';
  //set axios headers to application/json
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  return (
    <FlareErrorBoundary>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>RipeMetrics</title>
          <meta
            name="viewport"
            content="initial-scale=1, width=device-width"
          />
        </Head>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <HubspotProvider>
              <AuthProvider>
                <DarkModeProvider>
                  <DarkModeConsumer>
                    {({ darkMode }) => (
                      <ThemeProvider
                        theme={createTheme({
                          responsiveFontSizes: darkMode.responsiveFontSizes,
                          mode: darkMode.theme
                        })}
                      >
                        <CssBaseline />
                        <Toaster
                          position="bottom-right"
                          reverseOrder={true}
                        />
                        <AuthConsumer>
                          {(auth) =>
                            !auth.isInitialized ? (
                              <SplashScreen />
                            ) : (
                              getLayout(
                                <ErrorBoundary>
                                  <Component {...pageProps} />
                                </ErrorBoundary>
                              )
                            )
                          }
                        </AuthConsumer>
                      </ThemeProvider>
                    )}
                  </DarkModeConsumer>
                </DarkModeProvider>
              </AuthProvider>
            </HubspotProvider>
          </LocalizationProvider>
        </ReduxProvider>
        <FacebookPixel />
        <StripeBillingScript />
        <LinkedInTrackingCode />
        <GoogleGlobalSiteTag />
      </CacheProvider>
    </FlareErrorBoundary>
  );
};

export default App;
