import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductFormData {
  id: string;
  productName?: string;
  productDescription?: string;
  unitPriceInCents?: number;
  isPurchasable?: boolean;
  isActive?: boolean;
  category?: string;
  weightPerUnit?: number | null;
}

export interface ProductFormState {
  productFormData: ProductFormData | null;
}

const initialState: ProductFormState = {
  productFormData: null
};

export const productFormSlice = createSlice({
  name: 'productForm',
  initialState,
  reducers: {
    setProductFormData: (
      state,
      action: PayloadAction<ProductFormData | null>
    ) => {
      state.productFormData = action.payload;
    },
    clearProductFormData: (state) => {
      state.productFormData = null;
    }
  }
});

export const { setProductFormData, clearProductFormData } =
  productFormSlice.actions;

export default productFormSlice.reducer;
