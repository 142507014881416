import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export interface LoginRequest {
  username: string;
  password: string;
}

interface User {
  id: number;
  name: string;
  email: string;
  emailRaw: string;
}

export interface UserResponse {
  user: User;
  token: string;
}

type FetchResult = {
  data: unknown;
  error: { originalStatus: number };
};
export interface passwordResetRequest {
  email: string | null;
}

export interface passwordResetResponse {
  message: string;
}

const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (!headers.get('Content-Type')) {
        headers.set('Accept', 'application/json');
      }

      // headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', 'https://ripemetrics.com');
      // headers.set('Access-Control-Request-Headers', 'x-xsrf-token');

      return headers;
    }
  }),

  tagTypes: [
    'BusinessIntelligenceOverviewData',
    'ProductPerformanceSummaryData',
    'ProductPerformancePerformanceData',
    'ProductPerformanceReconsiderData',
    'ShoppingTrendsSummaryData',
    'ShoppingTrendsTrendsData',
    'ShoppingTrendsEcommerceData',
    'CustomerBehaviorsSummaryData',
    'CustomerBehaviorsBehaviorsData',
    'CustomerBehaviorsRetentionData',
    'MultiLocationAnalysisData',
    'MultiLocationLocationsData',
    'CalendarItems',
    'CommunicationTheaterData',
    'CustomerProfileData',
    'CustomerFeedbackSummaryData',
    'CustomerFeedbackFeedbackData',
    'MarketingAppraisalSummaryData',
    'MarketingAppraisalAppraisalData',
    'MarketingAppraisalAudienceData',
    'EnhancedMarketingIntelligenceSummaryData',
    'EnhancedSegmentsAutomatedSegmentsData',
    'EnhancedMarketingGroupsCreateAGroupData',
    'EnhancedSegmentsCreateASegmentData',
    'EnhancedCampaignsAutomatedCampaignsData',
    'EnhancedCampaignsCreateACampaignData',
    'EnhancedCampaignsInitiatedCampaignsData',
    'EnhancedLandingPageData',
    'AllContactsMapsData',
    'AllContactsProfilesData',
    'AllContactUploadData',
    'EmployeeEngagementSummaryData',
    'EmployeeEngagementFeedbackData',
    'MonthlyReportsData',
    'StoreData',
    'StoreUsers',
    'CampaignMetricsData',
    'DealsOfTheMonth',
    'Narratives',
    'Organizations',
    'OnboardingOrganizationData',
    'GroupedSuperInteractionsData',
    'SuperInteractionsOrdinalOptionsData',
    'ProspectManagementOpportunitiesData',
    'ProspectsManagementProspectsData',
    'ProspectManagementSummaryData',
    'smtp_data',
    'pop_data',
    'CommunicationVolumeSummaryData',
    'CommunicationVolumeVolumeData',
    'CommunicationVolumeContactsData',
    'AiRecommendationsStatCards',
    'AiRecommendationsBusinessTrendsSummary',
    'AiRecommendationsCustomerLifetimeValueSummary',
    'AiRecommendationsCustomerTrendsSummary',
    'AiRecommendationsRevenueData',
    'AiRecommendationsCustomerRetentionData',
    'AiRecommendationsCustomerGrowthData',
    'AiRecommendationsSummaryBubbleChartData',
    'AiRecommendationsCustomerTrendsStackedBarChartData',
    'AiChatBotTest',
    'AiRecommendationsCustomerTrendsAccordions'
  ],
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    login: builder.mutation<FetchResult, LoginRequest>({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials
      })
    }),
    passwordReset: builder.mutation<
      passwordResetResponse,
      passwordResetRequest
    >({
      query: (email) => ({
        url: '/password_reset_request',
        method: 'POST',
        body: email
      })
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected'
    })
  })
});

export const {
  useLoginMutation,
  useProtectedMutation,
  usePasswordResetMutation
} = api;
