import { useContext } from 'react';
import { AuthContext, AuthContextValue } from 'src/lib/contexts/auth-context';
import { store } from '@/lib/state/store';
import type { User } from '../../types/user';
// import { createResourceId } from '../../utils/create-resource-id';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from '../../utils/jwt';
import { wait } from '../../utils/wait';

const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}api`;

// demo user - bringing in user via authContext
// const users: User[] = [
//   {
//     id: '5e86809283e28b96d2d38537',
//     avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
//     email: 'demo@devias.io',
//     name: 'Andrew',
//     password: 'Password123!',
//     plan: 'Premium'
//   }
// ];

class AuthApi {
  // login is a function that takes in an email and password and returns a promise of a string.
  async login({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<string> {
    await wait(500);

    // Returning a promise that resolves to a string.
    return new Promise((resolve, reject) => {
      // Find the user
      try {
        // Creating an object with the username and password.
        const credentials = {
          username: email,
          password: password
        };
        // Creating an object with the login credentials method, headers, and body.
        const options = {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        };
        // Making a fetch request to the login endpoint.
        //@ts-ignore
        fetch(`${baseUrl}/login`, options)
          .then((response) => response.json())
          .then((response) => {
            /* Returning the user token */
            resolve(response.token);
          })
          .catch((err) => console.error(err));
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async register({
    email,
    name,
    password
  }: {
    email: string;
    name: string;
    password: string;
  }): Promise<string> {
    await wait(1000);

    return new Promise((resolve, reject) => {
      /* Getting the users from the AuthContext. */
      const users = useContext<AuthContextValue>(AuthContext);
      try {
        // Check if a user already exists
        //@ts-ignore - Property 'find' does not exist on type 'AuthContextValue'.
        const user = users.find((_user) => _user.email === email);

        if (user) {
          reject(new Error('User already exists'));
          return;
        }

        // user = {
        //   id: createResourceId(),
        //   avatar: undefined,
        //   email,
        //   name,
        //   password,
        //   plan: 'Standard'
        // };
        //@ts-ignore - Property 'push' does not exist on type 'AuthContextValue'.
        users.push(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN
        });

        resolve(accessToken);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(accessToken: string): Promise<User> {
    return new Promise((resolve, reject) => {
      let user;

      try {
        // Decode access token
        // Find the user
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            // Authorization: 'Bearer ' + accessToken
          }
        };
        // fetchAPI endpoint takes in bearer token and checks to see what user corresponds then returns an object of that users information
        fetch(`${baseUrl}/check`, options)
          .then((response) => response.json())
          .then((response) => {
            //creating a new user object with user information and accessToken to be later used.
            user = { ...response, token: accessToken };
            resolve(user);
          })
          .catch((err) => {
            window.localStorage.removeItem('accessToken');
            reject(new Error('Access Token is Invalid'));
          });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}
export const authApi = new AuthApi();
